import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ImageStack from '../components/imageStack';

import Layout from '../components/layout';
import SEO from '../components/seo';

class AnimationGridTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className={`post-content ${post.frontmatter.thumb || 'no-image'}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">{post.frontmatter.description}</p>
          )}

          {post.frontmatter.thumb && (
            <div className="post-content-image">
              <ImageStack
                main={post.frontmatter.thumb}
                over={post.frontmatter.finished}
                reveal
              />
            </div>
          )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          { post.frontmatter.printFile && (
            <>
              <a href={post.frontmatter.printFile.publicURL} className="button print primary fit large" style={{ fontWeight: 600 }}>
                Print
              </a>
              {/*  <a href={post.frontmatter.printFile.publicURL} className="button email primary fit large" style={{fontWeight: 600}}>
                Email
              </a>  */}
            </>
          )}
        </article>
      </Layout>
    );
  }
}

export default AnimationGridTemplate;

export const pageQuery = graphql`
  query AnimationGridBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumb {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        finished {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        printFile {
          id
          publicURL
          name
        }
      }
    }
  }
`;
